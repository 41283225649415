<template>
    <div>
        <el-page-header @back="backMain" :content="'应收账务 > ' + title"></el-page-header>
        <el-form class="detail_form" size="small" label-position="right" label-width="130px">
            <div class="form_item">
                <div class="head_title">出库单据详情</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="出库单据编号">{{ orderDetail.receiptNo }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="出库时间">{{ orderDetail.createT }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="出库分拣中心">{{ formatUnit(orderDetail.unitId) }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="利用单位">{{ formatUUnit(orderDetail.uUnitId) }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="利用方式">{{ orderDetail.usage }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="备注">{{ orderDetail.remark }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="confirm">
                    <el-col :span="8">
                        <el-form-item label="提交结算人">{{ orderDetail.dUserNa }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="提交结算时间">{{ orderDetail.decideT }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="结算合计金额">{{ aTotal }}元</el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="confirm">
                    <el-col :span="8">
                        <el-form-item label="是否生成对账单">{{ formatDZReceiptNo(orderDetail.tallyInfo) }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否对账确认">{{ formatVerify(orderDetail.tallyInfo) }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否收款">{{ formatPayee(orderDetail.tallyInfo) }}</el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="form_item">
                <div class="head_title">出库详情</div>
                <el-table :data="dataList">
                    <el-table-column label="物资名称" prop="cName"></el-table-column>
                    <el-table-column label="编码" prop="code"></el-table-column>
                    <el-table-column label="自定义编码" prop="cCode"></el-table-column>
                    <el-table-column label="型号" prop="model"></el-table-column>
                    <el-table-column label="类别" prop="recycleTNa"></el-table-column>
                    <el-table-column label="单位" prop="unit">kg</el-table-column>
                    <el-table-column label="出库量" prop="amount"></el-table-column>
                    <el-table-column label="参考价格" prop="refPrice"></el-table-column>
                    <el-table-column label="单价">
                        <template v-slot="scope">
                            <el-input v-if="edit" v-model="scope.row.price"></el-input>
                            <span v-else>{{ scope.row.price }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="金额">
                        <template v-slot="scope">{{ (scope.row.price * scope.row.amount).toFixed(2) }}</template>
                    </el-table-column>
                </el-table>
                <p class="amount_total">合计金额：{{ aTotal.toFixed(2) }}</p>
            </div>
        </el-form>
        <div v-if="edit" class="account_buttons">
            <el-button type="primary" size="small" @click="makeSure">提交结算</el-button>
            <el-button size="small" @click="backMain">取消</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            backMain() {
                this.$emit("switch", this.source);
            },
            makeSure() {
                const check = this.dataList.every(v => !isNaN(v.price) && v.price > 0);
                if (check) {
                    this.$http
                        .post("/bill/decidePrice", {
                            ...this.orderDetail,
                            ioT: "0",
                            detailList: this.dataList.map(v => {
                                return {
                                    materialId: v.materialId,
                                    price: v.price,
                                    amount: v.amount,
                                    detailId: v.detailId
                                };
                            })
                        })
                        .then(res => {
                            this.$message.success({
                                message: res.msg
                            });
                            this.backMain();
                        });
                } else {
                    this.$message.warning({
                        message: "确认价格不能小于0或者输入价格不能为非数字"
                    });
                }
            },
            formatUUnit(id) {
                const w = this.uUnitList.find(v => v.unitId === id);
                return w ? w.cname : "-";
            },
            formatUnit(key) {
                const u = this.dUnitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formatDZReceiptNo(tallyInfo) {
                return tallyInfo && tallyInfo.receiptNo ? "是" : "否";
            },
            formatVerify(tallyInfo) {
                if (tallyInfo && tallyInfo.verify) {
                    return tallyInfo.verify == "1" ? "是" : "否";
                } else {
                    return "否";
                }
            },
            formatPayee(tallyInfo) {
                if (tallyInfo && tallyInfo.amount == tallyInfo.rAmount) {
                    return "已收款";
                } else if (tallyInfo && tallyInfo.rAmount != 0) {
                    return "部分收款";
                } else {
                    return "未收款";
                }
            }
        },
        mounted() {
            const option = this.$parent.option;
            this.orderDetail = option;
            this.source = option.source;
            this.edit = option.edit;
            this.confirm = option.confirm;

            if (this.edit) {
                this.title = "确认单价";
            } else {
                this.title = "查看";
            }
            this.$http.post("/stock/io/queryIODetailByIoStockId", option.ioStockId).then(res => {
                this.dataList = res.detail.map(v => {
                    return {
                        ...v,
                        ...v.material,
                        refPrice: v.material.price,
                        price: v.price || 0,
                        recycleTNa: v.recycleType.cName
                    };
                });
            });
        },
        computed: {
            useTypes() {
                return this.$parent.useTypes;
            },
            dUnitList() {
                return this.$parent.dUnitList;
            },
            uUnitList() {
                return this.$parent.uUnitList;
            },
            aTotal() {
                if (this.dataList) {
                    return this.dataList.reduce((total, cur) => {
                        const money = isNaN(cur.price) ? 0 : cur.amount * cur.price;
                        return total + money;
                    }, 0);
                } else {
                    return 0;
                }
            }
        },
        data() {
            return {
                orderDetail: {},
                dataList: [],
                source: "",
                title: "确认单价",

                edit: false,
                confirm: false
            };
        }
    };
</script>
<style lang="scss" scoped>
    .amount_total {
        color: red;
        text-align: right;
        padding-right: 80px;
        margin-top: 10px;
    }
    .account_buttons {
        text-align: center;
    }
    .detail_form {
        margin-top: 20px;
        .form_item {
            margin-bottom: 30px;
            .head_title {
                font-size: 18px;
            }
            .head_title::before {
                content: "";
                display: inline-block;
                width: 7px;
                height: 26px;
                margin-right: 5px;
                vertical-align: middle;
                background-color: #3498db;
            }
        }
    }
</style>
