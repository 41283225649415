<template>
    <div>
        <jy-query ref="form" :model="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="出库单据编号" prop="receiptNo">
                <el-input v-model="form.receiptNo"></el-input>
            </jy-query-item>
            <jy-query-item label="出库分拣中心" prop="unitId">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in dUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="利用单位" prop="uUnitId">
                <el-select v-model="form.uUnitId">
                    <el-option v-for="u in uUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="利用方式" prop="usage">
                <el-select v-model="form.usage">
                    <el-option v-for="t in useTypes" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="回收类别" prop="recycleT">
                <div @click="selecrecycleTs">
                    <el-input placeholder="请选择" v-model="form.recycleTNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="物资名称" prop="materialNa">
                <el-input v-model="form.materialNa"></el-input>
            </jy-query-item>
            <jy-query-item label="提交结算人" prop="dUserNa">
                <el-input v-model="form.dUserNa"></el-input>
            </jy-query-item>
            <jy-query-item label="出库时间" prop="createT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.createT"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="提交结算时间" prop="verifyT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.verifyT"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="对账单号" prop="DZReceiptNo">
                <el-input v-model="form.DZReceiptNo"></el-input>
            </jy-query-item>
            <jy-query-item label="是否生成对账单" prop="taStatus">
                <el-select v-model="form.taStatus">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="已生成" value="1"></el-option>
                    <el-option label="未生成" value="0"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="是否对账确认" prop="verify">
                <el-select v-model="form.verify">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="是否收款" prop="hasRAmount">
                <el-select v-model="form.hasRAmount">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="已收款" value="2"></el-option>
                    <el-option label="部分收款" value="1"></el-option>
                    <el-option label="未收款" value="0"></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesSettleCenterReceiveAccountList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesSettleCenterReceiveAccountReset')">重置</el-button>
                <el-row>
                    <el-button size="small" type="primary" v-on:click="batchGenerate" v-if="btnexist('wastesSettleCenterReceiveAccountDecide')">生成对账单</el-button>
                    <el-button size="small" type="primary" @click="showDefineTable">自定义列表</el-button>
                </el-row>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table ref="billTable" :data="tableData">
                <jy-table-column type="selection"></jy-table-column>
                <jy-table-column type="index" label="序号" width="55"></jy-table-column>
                <jy-table-column label="出库单据编号" prop="receiptNo" min-width="170"></jy-table-column>
                <jy-table-column label="出库时间" prop="createT" min-width="160"></jy-table-column>
                <jy-table-column label="出库分拣中心" prop="unitId" min-width="150">
                    <template v-slot="scope">{{ formatUnit(scope.row.unitId) }}</template>
                </jy-table-column>
                <jy-table-column label="利用单位" prop="uUnitId" min-width="150">
                    <template v-slot="scope">{{ formatUUnit(scope.row.uUnitId) }}</template>
                </jy-table-column>
                <jy-table-column label="利用方式" prop="usage" min-width="120"></jy-table-column>
                <jy-table-column label="出库物资" prop="goods" min-width="160">
                    <template v-slot="scope">{{ formatMaterial(scope.row.detailList) }}</template>
                </jy-table-column>
                <jy-table-column label="类别" prop="goodsType" min-width="120">
                    <template v-slot="scope">{{ formatrecycleTs(scope.row.detailList) }}</template>
                </jy-table-column>
                <jy-table-column label="合计金额" prop="total" min-width="120">
                    <template v-slot="scope">{{ formatSum(scope.row.detailList) }}</template>
                </jy-table-column>
                <jy-table-column label="提交结算人" prop="dUserNa" min-width="120"></jy-table-column>
                <jy-table-column label="提交结算时间" prop="decideT" min-width="160"></jy-table-column>
                <jy-table-column label="对账单号" prop="DZReceiptNo" min-width="160">
                    <template v-slot="scope">{{ formatDZReceiptNo(scope.row.tallyInfo) }}</template>
                </jy-table-column>
                <jy-table-column label="是否对账确认" prop="verify" min-width="120">
                    <template v-slot="scope">{{ formatVerify(scope.row.tallyInfo) }}</template>
                </jy-table-column>
                <jy-table-column label="是否收款" prop="payee" min-width="120">
                    <template v-slot="scope">{{ formatPayee(scope.row.tallyInfo) }}</template>
                </jy-table-column>
                <jy-operate v-bind:list="operateList" width="140"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <t-fixed ref="tFixed" tagTitle="label" :banList="['name', 'orgName']"></t-fixed>
        <recycle-types-tree ref="recycle" @addInstitutions="addRecycleType"></recycle-types-tree>
    </div>
</template>

<script>
    import TFixed from "@/components/pages/admin/common/TFixed/tFixed.vue";
    import RecycleTypesTree from "@/components/pages/admin/common/dialog-tree/recycleTypes_tree.vue";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        mixins: [btnMixins],
        methods: {
            addRecycleType(data) {
                this.form.recycleTNa = data.cName;
                this.form.recycleT = data.recycleTId;
            },
            // 选择机构树
            selecrecycleTs() {
                this.$refs.recycle.init();
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.recycleTNa = "";
                this.oncheck();
            },
            getList() {
                this.loading = true;
                let params = {
                    hasDT: "1",
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    ...this.form,
                    timeStart: this.$util.addTime(this.form.createT && this.form.createT[0], true),
                    timeEnd: this.$util.addTime(this.form.createT && this.form.createT[1]),
                    decideTStart: this.$util.addTime(this.form.verifyT && this.form.verifyT[0], true),
                    decideTEnd: this.$util.addTime(this.form.verifyT && this.form.verifyT[1]),
                    createT: undefined,
                    verifyT: undefined
                };
                this.$http
                    .post("/bill/pageQuery", params)
                    .then(res => {
                        this.tableData = res.detail.list;
                        this.total = res.detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            showDefineTable() {
                this.$refs.tFixed.init(this.columns);
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            formatUUnit(id) {
                const w = this.uUnitList.find(v => v.unitId === id);
                return w ? w.cname : "-";
            },
            formatUnit(key) {
                const u = this.dUnitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formatPayee(tallyInfo) {
                if (tallyInfo && tallyInfo.amount == tallyInfo.rAmount) {
                    return "已收款";
                } else if (tallyInfo && tallyInfo.rAmount != 0) {
                    return "部分收款";
                } else {
                    return "未收款";
                }
            },
            formatVerify(tallyInfo) {
                if (tallyInfo && tallyInfo.verify) {
                    return tallyInfo.verify == "1" ? "是" : "否";
                } else {
                    return "否";
                }
            },
            formatDZReceiptNo(tallyInfo) {
                return tallyInfo ? tallyInfo.receiptNo : "";
            },
            formatMaterial(list) {
                if (list && list.length) {
                    const mStrList = list.map(v => {
                        return v.material.cName + v.amount + "kg";
                    });
                    return mStrList.join(",");
                } else {
                    return "-";
                }
            },
            formatSum(list) {
                if (list && list.length) {
                    const sum = list.reduce((total, v) => {
                        if (isNaN(v.amount) || isNaN(v.price)) {
                            return total;
                        } else {
                            return total + v.amount * v.price;
                        }
                    }, 0);
                    return sum.toFixed(2);
                } else {
                    return "-";
                }
            },
            formatrecycleTs(list) {
                if (list && list.length) {
                    return list.map(v => v.material.recycleTStr).join(",");
                } else {
                    return "-";
                }
            },
            handleView(row) {
                this.$emit("switch", "Detail", {
                    ...row,
                    source: "Submit",
                    confirm: true
                });
            },
            batchGenerate() {
                const list = this.$refs.billTable.selection;
                const typeSet = new Set(list.map(v => v.uUnitId));
                const DZReceiptNos = list.map(v => v.tallyInfo && v.tallyInfo.receiptNo).filter(v => !!v);
                if (typeSet.size === 1 && !DZReceiptNos.length) {
                    if (list.length) {
                        this.$confirm("是否将选中的应收账务转为对账单?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning"
                        })
                            .then(() => {
                                this.$http.post("/bill/batchSetTaStatus", list.map(v => v.ioStockId).join(",")).then(res => {
                                    this.$message.success({
                                        message: res.msg
                                    });
                                    this.getList();
                                });
                            })
                            .catch(() => {
                                this.$message({
                                    type: "info",
                                    message: "已取消"
                                });
                            });
                    } else {
                        this.$message.warning({
                            message: "请选择已提交的应收账务"
                        });
                    }
                } else {
                    if (this.typeSet === 0) {
                        this.$message.warning({
                            message: "请选择单据"
                        });
                    } else if (DZReceiptNos.length) {
                        this.$message.warning({
                            message: `${DZReceiptNos.join(",")}已生成对账单号，请选择没有生成对账单号的单据`
                        });
                    } else {
                        this.$message.warning({
                            message: "只能选择同一利用单位的单据"
                        });
                    }
                }
            },
            handleRecall(row) {
                this.$confirm("是否撤回该已结算的应收对账单，撤回不可恢复?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        this.$http.post("/bill/unDecidePrice", row.ioStockId).then(res => {
                            this.$message.success({
                                message: res.msg
                            });
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消撤回"
                        });
                    });
            },
            setOperateList() {
                let l = [
                    {
                        name: "查看",
                        icon: "el-icon-edit-outline",
                        fun: this.handleView,
                        isShow: () => {
                            return this.btnexist("wastesSettleCenterReceiveAccountView");
                        }
                    },
                    {
                        name: "撤回",
                        icon: "el-icon-edit-outline",
                        fun: this.handleRecall,
                        isShow: row => {
                            return row.taStatus == "0" && this.btnexist("wastesSettleCenterReceiveAccountBack");
                        }
                    }
                ];
                this.operateList = l;
            }
        },
        mounted() {
            this.getList();

            const columns = this.$refs.tFixed.showList;
            this.columnProps = columns.length ? columns : this.columns.map(v => v.prop);
        },
        created() {
            this.setOperateList();
        },
        computed: {
            useTypes() {
                return this.$parent.useTypes;
            },
            dUnitList() {
                return this.$parent.dUnitList;
            },
            uUnitList() {
                return this.$parent.uUnitList;
            }
        },
        data() {
            return {
                loading: false,
                tableData: [],
                total: 0,

                form: {
                    receiptNo: "",
                    unitId: "",
                    uUnitId: "",
                    usage: "",
                    recycleT: "",
                    recycleTNa: "",
                    materialNa: "",
                    dUserNa: "",
                    createT: [],
                    verifyT: [],
                    DZReceiptNo: "",
                    taStatus: "",
                    verify: "",
                    hasRAmount: ""
                },
                pageIndex: 1,
                pageSize: 10,

                operateList: [],

                btnMenuId: "wastesSettleCenterReceiveAccount",

                columns: [
                    { label: "出库单据编号", prop: "receiptNo" },
                    { label: "出库时间", prop: "createT" },
                    { label: "出库分拣中心", prop: "unitId" },
                    { label: "利用单位", prop: "uUnitId" },
                    { label: "利用方式", prop: "usage" },
                    { label: "出库物资", prop: "goods" },
                    { label: "类别", prop: "goodsType" },
                    { label: "合计金额", prop: "total" },
                    { label: "提交结算人", prop: "dUserNa" },
                    { label: "提交结算时间", prop: "decideT" },
                    { label: "对账单号", prop: "DZReceiptNo" },
                    { label: "是否对账确认", prop: "verify" },
                    { label: "是否收款", prop: "payee" }
                ],
                columnProps: []
            };
        },
        components: {
            TFixed,
            RecycleTypesTree
        }
    };
</script>
<style></style>
